import { Injectable } from '@angular/core';
import { AnalyticsData } from '@app/analysis-results/models/analytics-data';
import { DEFAULT_PIE_SERIES_OPTIONS } from '@app/analysis-results/models/default-series-options';
import { GeneratePieSeriesOptions } from '@app/analysis-results/models/generate-pie-series-options';
import { ExanteAnalysisBarChartsService } from '@app/analysis-results/services/exante-analysis-bar-charts.service';
import { ExanteAnalysisPieChartsService } from '@app/analysis-results/services/exante-analysis-pie-charts.service';
import { AvailableLang } from '@app/core/const/i18n';
import { palette } from '@app/core/const/palette';
import { Allocation, Asset, InstrumentAnalysisResult } from '@app/core/models';
import { EchartParamsData, OtherData } from '@app/core/models/echarts-type';
import { defaultTooltip, rgbToHex, tooltipFormatter } from '@app/core/tools/echarts-tools';
import { ChartType } from '@app/shared/components';
import { TranslocoService } from '@ngneat/transloco';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { OrdinalRawValue, TextCommonOption } from 'echarts/types/src/util/types';

@Injectable({
  providedIn: 'root',
})
export class ExanteAnalysisChartsService {
  private readonly _contributionSeriesIds = { other: 1, cash: 2, credit: 3 };

  constructor(
    private readonly _translocoService: TranslocoService,
    private readonly _exanteAnalysisPieChartsService: ExanteAnalysisPieChartsService,
    private readonly _exanteAnalysisBarChartsService: ExanteAnalysisBarChartsService
  ) {}

  getContributionOptions(
    allocation: Allocation,
    assetsList: Asset[],
    currentLang: AvailableLang,
    chartType: ChartType,
    overrides?: Partial<EChartsOption>,
    pieSeriesOptions: GeneratePieSeriesOptions = DEFAULT_PIE_SERIES_OPTIONS
  ): EChartsOption {
    const otherPositive: OtherData = { weight: [], return: [], risk: [] };
    const otherNegative: OtherData = { weight: [], return: [], risk: [] };
    const maxYAxis = this._getContributionMaxYAxis(allocation.exAnteAnalysis.instrumentResults);

    allocation.exAnteAnalysis.instrumentResults.forEach(instrumentResult => {
      const currentAsset = assetsList.find(asset => asset.id === instrumentResult.instrumentId);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { instrumentId, ...instrumentAnalytics } = instrumentResult;

      // set other positive values
      Object.keys(instrumentAnalytics).forEach((analyticsKey: 'return' | 'risk' | 'weight') => {
        if (this._isInOtherPositive(instrumentAnalytics[analyticsKey], maxYAxis)) {
          otherPositive[analyticsKey].push({
            id: currentAsset.id,
            name: currentAsset.name[currentLang],
            value: instrumentAnalytics[analyticsKey],
          });
        }
      });

      // set other negative values
      Object.keys(instrumentAnalytics).forEach((analyticsKey: 'return' | 'risk' | 'weight') => {
        if (this._isInOtherNegative(instrumentAnalytics[analyticsKey], maxYAxis)) {
          otherNegative[analyticsKey].push({
            id: currentAsset.id,
            name: currentAsset.name[currentLang],
            value: instrumentAnalytics[analyticsKey],
          });
        }
      });
    });
    if (chartType === 'bar') {
      return this._getContribBarChartOptions(
        otherPositive,
        otherNegative,
        allocation,
        assetsList,
        currentLang,
        maxYAxis
      );
    } else {
      return this._getContribPieChartOptions(
        otherPositive,
        allocation,
        assetsList,
        currentLang,
        overrides,
        pieSeriesOptions
      );
    }
  }

  private _isInOtherPositive(analyticsValue: number, maxYAxis: number): boolean {
    return analyticsValue >= 0 && (analyticsValue * 100) / maxYAxis < 3;
  }

  private _isInOtherNegative(analyticsValue: number, maxYAxis: number): boolean {
    return analyticsValue < 0 && (analyticsValue * 100) / maxYAxis > -3;
  }

  private _mustDisplayOther(other: OtherData): boolean {
    return other.weight.length > 1 || other.return.length > 1 || other.risk.length > 1;
  }

  private _otherToDisplay(other: OtherData): OtherData {
    const otherData: OtherData = { ...other };
    Object.keys(otherData).forEach((analyticsKey: 'return' | 'risk' | 'weight') => {
      if (otherData[analyticsKey].length === 1) {
        otherData[analyticsKey] = [];
      }
    });
    return otherData;
  }

  private _getContribPieChartOptions(
    otherPositive: OtherData,
    allocation: Allocation,
    assetsList: Asset[],
    currentLang: AvailableLang,
    overrides: Partial<EChartsOption>,
    pieSeriesOptions: GeneratePieSeriesOptions
  ): EChartsOption {
    const otherPositiveToDisplay = this._mustDisplayOther(otherPositive) ? this._otherToDisplay(otherPositive) : null;
    const series = this._exanteAnalysisPieChartsService.getContributionSeries(
      allocation.exAnteAnalysis.instrumentResults,
      assetsList,
      currentLang,
      otherPositiveToDisplay
    );
    return this._exanteAnalysisPieChartsService.getContribBasePieOptions(
      series,
      { ...(overrides ?? {}), allocation: { name: allocation.name, id: allocation.id } },
      pieSeriesOptions
    );
  }

  private _getContribBarChartOptions(
    otherPositive: OtherData,
    otherNegative: OtherData,
    allocation: Allocation,
    assetsList: Asset[],
    currentLang: AvailableLang,
    maxYAxis: number
  ): EChartsOption {
    const getNotInOtherAnalyticsValue = (
      analyticsValue: number,
      analyticsKey: 'return' | 'risk' | 'weight'
    ): number => {
      return (this._isInOtherPositive(analyticsValue, maxYAxis) && otherPositive[analyticsKey].length > 1) ||
        (this._isInOtherNegative(analyticsValue, maxYAxis) && otherNegative[analyticsKey].length > 1)
        ? null
        : analyticsValue;
    };
    const notInOtherInstrumentValues = allocation.exAnteAnalysis.instrumentResults.map(instrumentResult => ({
      ...instrumentResult,
      weight: getNotInOtherAnalyticsValue(instrumentResult.weight, 'weight'),
      return: getNotInOtherAnalyticsValue(instrumentResult.return, 'return'),
      risk: getNotInOtherAnalyticsValue(instrumentResult.risk, 'risk'),
    }));
    const series = this._exanteAnalysisBarChartsService.getContributionBarSeries(
      notInOtherInstrumentValues,
      assetsList,
      currentLang,
      maxYAxis
    );

    // set others series if they contain more than one instrument
    if (this._mustDisplayOther(otherPositive)) {
      const otherPositiveToDisplay = this._otherToDisplay(otherPositive);
      series.unshift(
        this._buildContributionBarSerie(
          this._contributionSeriesIds.other,
          `${this._translocoService.translate('analysisResults.contribution.others')} (+)`,
          palette.grey[400],
          this._getContribOtherValues(otherPositiveToDisplay),
          maxYAxis,
          Object.values(otherPositiveToDisplay)
        )
      );
    }
    if (this._mustDisplayOther(otherNegative)) {
      const otherNegativeToDisplay = this._otherToDisplay(otherNegative);
      series.unshift(
        this._buildContributionBarSerie(
          -this._contributionSeriesIds.other,
          `${this._translocoService.translate('analysisResults.contribution.others')} (-)`,
          palette.grey[400],
          this._getContribOtherValues(otherNegativeToDisplay),
          maxYAxis,
          Object.values(otherNegativeToDisplay)
        )
      );
    }
    return this._getContribBaseBarOptions(
      this._sortSeriesByIds(series),
      assetsList,
      currentLang,
      this._translocoService.translate('analysisResults.contribution.analytics-labels'),
      maxYAxis
    );
  }

  private _sortSeriesByIds(series: BarSeriesOption[]): BarSeriesOption[] {
    const compareIds = (a: BarSeriesOption, b: BarSeriesOption): number => {
      const idA = parseInt(String(a.id), 10);
      const idB = parseInt(String(b.id), 10);
      if (idA > idB) {
        return 1;
      }
      if (idA < idB) {
        return -1;
      }
      return 0;
    };

    return [...series].sort(compareIds);
  }

  private _buildContributionBarSerie(
    id: number,
    name: string,
    color: string,
    values: number[],
    maxYAxis: number,
    otherData?: AnalyticsData
  ): BarSeriesOption {
    return {
      id: id.toString(),
      name,
      type: 'bar',
      stack: 'contribution',
      barMinHeight: 8,
      barCategoryGap: '10%',
      data: values.map((value, index) => ({
        name,
        value: value !== 0 ? value : null,
        label: {
          show: (value * 100) / maxYAxis > 3 || (value * 100) / maxYAxis < -3,
          position: 'inside',
          color: palette.white[400],
        },
        tooltip: {
          ...defaultTooltip,
          formatter: otherData
            ? tooltipFormatter(
                [name],
                otherData[index].map(item => ({ ...item, values: [`${item.value}%`] }))
              )
            : '{b} : {c}%',
        },
        itemStyle: { color: otherData ? color : rgbToHex(color), borderColor: '#fff', borderWidth: 2 },
      })),
    };
  }

  private _getContribMediaOption(
    series: BarSeriesOption[],
    assetsList: Asset[],
    currentLang: AvailableLang,
    fontSize: number,
    maxLength: number
  ): any {
    return {
      xAxis: {
        axisLabel: { fontSize },
      },
      series: series.map(() => ({
        label: {
          formatter: (params: EchartParamsData): string => {
            const nameType: 'name' | 'shortName' = maxLength > 20 ? 'name' : 'shortName';
            const currentAsset = assetsList.find(asset => asset.name[currentLang] === params.name);
            const label = currentAsset ? currentAsset[nameType][currentLang] : params.name;
            const assetName = label.length > maxLength ? `${label.substr(0, maxLength - 1)}.` : label;
            return `${assetName} ${params.value}%`;
          },
        },
      })),
    };
  }

  private _getContribOtherValues(otherData: OtherData): number[] {
    const getTotalValue = (dataArray: Array<{ name: string; value: number }>): number => {
      return dataArray.length > 0
        ? Math.round(
            dataArray.reduce((totalValue, item) => {
              return totalValue + item.value;
            }, 0) * 10
          ) / 10
        : null;
    };
    return [getTotalValue(otherData.weight), getTotalValue(otherData.return), getTotalValue(otherData.risk)];
  }

  private _getContribBaseBarOptions(
    series: BarSeriesOption[],
    assetsList: Asset[],
    currentLang: AvailableLang,
    xAxisData: Array<{ value: OrdinalRawValue; textStyle?: TextCommonOption }> | string[],
    maxYAxis: number
  ): EChartsOption {
    const seriesDataValues = series.map(seriesItem =>
      (seriesItem.data as Array<{ value: number }>).filter(dataItem => !!dataItem).map(dataItem => dataItem.value)
    );
    const totalNegative = seriesDataValues.reduce(
      (total, seriesItemValue) => {
        total.weight = seriesItemValue[0] < 0 ? total.weight + seriesItemValue[0] : total.weight;
        total.return = seriesItemValue[1] < 0 ? total.return + seriesItemValue[1] : total.return;
        total.risk = seriesItemValue[2] < 0 ? total.risk + seriesItemValue[2] : total.risk;
        return total;
      },
      { weight: 0, return: 0, risk: 0 }
    );

    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        grid: {
          left: 10,
          top: 50,
          right: 10,
          bottom: 20,
          height: '90%',
        },
        yAxis: {
          type: 'value',
          show: false,
          min: Math.min(...Object.values(totalNegative)) * 1.02,
          max: maxYAxis,
        },
        xAxis: {
          type: 'category',
          position: 'top',
          axisTick: { show: false },
          axisLabel: { verticalAlign: 'top', margin: 20 },
          data: xAxisData,
          z: 2,
        },
        tooltip: {
          trigger: 'item',
        },
        series,
      },
      media: [
        {
          query: {
            minWidth: 727,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 14, 25),
        },
        {
          query: {
            minWidth: 600,
            maxWidth: 726,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 14, 20),
        },
        {
          query: {
            minWidth: 520,
            maxWidth: 599,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 14, 16),
        },
        {
          query: {
            maxWidth: 519,
          },
          option: this._getContribMediaOption(series, assetsList, currentLang, 11, 12),
        },
      ],
    };
  }

  private _getContributionMaxYAxis(instrumentsResults: InstrumentAnalysisResult[]): number {
    const totalPositive = instrumentsResults.reduce(
      (total, instrumentResult) => {
        total.weight = instrumentResult.weight > 0 ? total.weight + instrumentResult.weight : total.weight;
        total.return = instrumentResult.return > 0 ? total.return + instrumentResult.return : total.return;
        total.risk = instrumentResult.risk > 0 ? total.risk + instrumentResult.risk : total.risk;
        return total;
      },
      { weight: 0, return: 0, risk: 0 }
    );
    return Math.max(...Object.values(totalPositive)) * 1.02;
  }
}
