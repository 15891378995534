import { Injectable } from '@angular/core';
import { DEFAULT_PIE_SERIES_OPTIONS } from '@app/analysis-results/models/default-series-options';
import { GeneratePieSeriesOptions } from '@app/analysis-results/models/generate-pie-series-options';
import { AvailableLang } from '@app/core/const/i18n';
import { palette, paletteAllocationsGreens } from '@app/core/const/palette';
import { Asset, AvailableLiquidity, InstrumentAnalysisResult, Liquidity } from '@app/core/models';
import { OtherData } from '@app/core/models/echarts-type';
import { defaultTooltip, hexToRgba, tooltipFormatter } from '@app/core/tools/echarts-tools';
import { TranslocoService } from '@ngneat/transloco';
import { BarSeriesOption, EChartsOption, ImagePatternObject, PieSeriesOption } from 'echarts';
import { TitleOption } from 'echarts/types/dist/shared';
import { OptionDataItemObject, ZRColor } from 'echarts/types/src/util/types';
import { uniq } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ExanteAnalysisPieChartsService {
  constructor(private readonly _translocoService: TranslocoService) {}

  getContributionSeries(
    instrumentsResults: InstrumentAnalysisResult[],
    assetsList: Asset[],
    currentLang: AvailableLang,
    othersToDisplay?: OtherData
  ): PieSeriesOption[] {
    const greyColor = palette.grey[400];
    const whiteColor = palette.white[400];
    const data = instrumentsResults.reduce(
      (carry, instrumentResult) => {
        const currentAsset = assetsList.find(asset => asset.id === instrumentResult.instrumentId);
        if (currentAsset) {
          const otherWeightsToDisplayIds = othersToDisplay?.weight.map(otherToDisplay => otherToDisplay.id) ?? [];
          const currentAssetColor = `rgb(${currentAsset.color[currentLang]})`;
          const negativeColor = this._createDiagonalPattern(currentAssetColor, whiteColor);
          if (!otherWeightsToDisplayIds.includes(currentAsset.id)) {
            const isNegative = instrumentResult.weight < 0;
            carry.weight.push({
              id: currentAsset.id,
              name: currentAsset.name[currentLang],
              itemStyle: { color: isNegative ? negativeColor : currentAssetColor },
              value: Math.abs(instrumentResult.weight),
              isNegative,
            });
          }
          const otherReturnsToDisplayIds = othersToDisplay?.return.map(otherToDisplay => otherToDisplay.id) ?? [];
          if (!otherReturnsToDisplayIds.includes(currentAsset.id)) {
            const isNegative = instrumentResult.return < 0;
            carry.return.push({
              id: currentAsset.id,
              name: currentAsset.name[currentLang],
              itemStyle: { color: isNegative ? negativeColor : currentAssetColor },
              value: Math.abs(instrumentResult.return),
              isNegative,
            });
          }
          const otherRisksToDisplayIds = othersToDisplay?.risk.map(otherToDisplay => otherToDisplay.id) ?? [];
          if (!otherRisksToDisplayIds.includes(currentAsset.id)) {
            const isNegative = instrumentResult.risk < 0;
            carry.risk.push({
              id: currentAsset.id,
              name: currentAsset.name[currentLang],
              itemStyle: { color: isNegative ? negativeColor : currentAssetColor },
              value: Math.abs(instrumentResult.risk),
              isNegative,
            });
          }
        }
        return carry;
      },
      { weight: [], return: [], risk: [] }
    );

    if (othersToDisplay?.weight != null && othersToDisplay.weight.length > 0) {
      const otherWeight = {
        id: -1,
        name: this._translocoService.translate('analysisResults.contribution.others'),
        value: othersToDisplay.weight.reduce((totalValue, item) => totalValue + item.value, 0),
        itemStyle: { color: greyColor },
        type: 'weight',
        otherData: othersToDisplay.weight,
      };
      data.weight = [...data.weight, otherWeight];
    }
    if (othersToDisplay?.return != null && othersToDisplay.return.length > 0) {
      const otherReturn = {
        id: -1,
        name: this._translocoService.translate('analysisResults.contribution.others'),
        value: othersToDisplay.return.reduce((totalValue, item) => totalValue + item.value, 0),
        itemStyle: { color: greyColor },
        type: 'return',
        otherData: othersToDisplay.return,
      };
      data.return = [...data.return, otherReturn];
    }
    if (othersToDisplay?.risk != null && othersToDisplay.risk.length > 0) {
      const otherRisk = {
        id: -1,
        name: this._translocoService.translate('analysisResults.contribution.others'),
        value: othersToDisplay.risk.reduce((totalValue, item) => totalValue + item.value, 0),
        itemStyle: { color: greyColor },
        type: 'risk',
        otherData: othersToDisplay.risk,
      };
      data.risk = [...data.risk, otherRisk];
    }
    return this._buildContributionSeries(data);
  }

  getComparisonContributionChartOptions(
    allocationsContributionOptions: EChartsOption[],
    overrides?: Partial<EChartsOption>,
    seriesOptions: GeneratePieSeriesOptions = DEFAULT_PIE_SERIES_OPTIONS
  ): EChartsOption[] {
    const comparisonSeries = allocationsContributionOptions.reduce<PieSeriesOption[][]>(
      (carry, allocationContributionOptions) => {
        const name = allocationContributionOptions.baseOption.allocation
          ? (
              allocationContributionOptions.baseOption.allocation as {
                name: string;
              }
            ).name
          : '';
        carry[0].push({
          ...allocationContributionOptions.baseOption.series[0],
          name,
        } as PieSeriesOption);
        carry[1].push({
          ...allocationContributionOptions.baseOption.series[1],
          name,
        } as PieSeriesOption);
        carry[2].push({
          ...allocationContributionOptions.baseOption.series[2],
          name,
        } as PieSeriesOption);
        return carry;
      },
      [[], [], []]
    );
    return comparisonSeries.map(series => {
      return this.getContribBasePieOptions(
        series,
        {
          ...overrides,
        },
        seriesOptions
      );
    });
  }

  getContribBasePieOptions(
    series: PieSeriesOption[],
    overrides?: Partial<EChartsOption & { allocation?: { name: string; id: number } }>,
    seriesOptions: GeneratePieSeriesOptions = DEFAULT_PIE_SERIES_OPTIONS
  ): EChartsOption {
    // width of each pie chart in percent (to leave room for the legend)
    const pieChartWidth = 60;
    // height of each pie chart in percent
    const pieChartHeight = 33.33;
    const othersLabel = this._translocoService.translate('analysisResults.contribution.others');
    const allInstrumentNames = uniq(
      series
        .filter((pieSeries: PieSeriesOption) => pieSeries.data != null)
        .map((pieSeries: PieSeriesOption) => pieSeries.data.map((data: { name: string }) => data.name))
        .flat()
    );
    const othersNameIndex = allInstrumentNames.indexOf(othersLabel);
    if (othersNameIndex >= 0) {
      // move others to the end if present
      allInstrumentNames.push(...allInstrumentNames.splice(othersNameIndex, 1));
    }
    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        tooltip: {
          ...defaultTooltip,
          formatter: (params): string => {
            const value = `${params.data.isNegative ? '-' : ''}${params.data.value}`;
            if (params.data.otherData == null || params.data.id >= 0) {
              return `${params.data.name} : ${value}%`;
            } else {
              return tooltipFormatter(
                [`${this._translocoService.translate('analysisResults.contribution.others')} (+)`],
                params.data.otherData.map(item => ({
                  ...item,
                  values: [`${item.value}%`],
                }))
              );
            }
          },
        },
        title: series.map(({ name }: { name: string }, index: number) => ({
          text: name.toUpperCase(),
          left: `${pieChartWidth / 2}%`,
          textAlign: 'center',
          textStyle: {
            width: '100%',
            color: paletteAllocationsGreens[index],
            ...((overrides?.title as TitleOption)?.textStyle ?? {}),
          },
          top: `${index * (pieChartHeight + seriesOptions.chartMargin)}%`,
        })),
        legend: {
          width: '40%',
          top: '2%',
          right: 0,
          orient: 'vertical',
          itemGap: 15,
          data: allInstrumentNames,
        },
        series: series.map(
          (pieSeries, index) =>
            ({
              ...pieSeries,
              height: `${pieChartHeight - seriesOptions.titleMargin - seriesOptions.chartMargin}%`,
              width: `${pieChartWidth}%`,
              top: `${index * (pieChartHeight + seriesOptions.chartMargin) + seriesOptions.titleMargin}%`,
              ...seriesOptions.overrides,
            }) as PieSeriesOption
        ),
        allocation: overrides.allocation ?? null,
      },
    };
  }

  getLiquidityOptions(
    liquidity: Array<{ label: string; value: number }>,
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const data: BarSeriesOption[] = liquidity
      .filter(liquidityItem => liquidityItem.value !== 0)
      .map(liquidityItem => ({
        name: availableLiquidities.find(availableLiquidity => availableLiquidity.name.en === liquidityItem.label).name[
          currentLang
        ],
        value: liquidityItem.value,
        itemStyle: {
          borderWidth: 3,
          borderColor: '#fff',
          color: availableLiquidities.find(availableLiquidity => availableLiquidity.name.en === liquidityItem.label)
            .color,
        },
        label: {
          color: availableLiquidities.find(availableLiquidity => availableLiquidity.name.en === liquidityItem.label)
            .color,
        },
      }));
    const sortedLiquidities = this._sortLiquiditiesByNames(availableLiquidities, data, currentLang);

    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        legend: {
          top: 'middle',
          right: '10%',
          orient: 'vertical',
          itemGap: 15,
          data: sortedLiquidities.map(dataItem => String(dataItem.name)),
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: true,
            animation: false,
            label: {
              show: false,
              position: 'center',
              verticalAlign: 'middle',
              borderRadius: 50,
              backgroundColor: palette.grey[50],
              fontSize: 18
            },
            emphasis: {
              label: {
                show: true,
                color: palette.grey[400],
                fontSize: 18
              },
            },
            labelLine: { show: false },
            data: sortedLiquidities,
          } as PieSeriesOption,
        ],
      },
      media: [
        {
          query: {
            minWidth: 444,
          },
          option: {
            legend: {
              right: '8%',
              textStyle: { fontSize: 12, padding: [0, 0, 0, 5] },
            },
            series: [
              {
                hoverOffset: 10,
                radius: ['40%', '80%'],
                center: ['33%', '50%'],
                label: {
                  padding: 20,
                  width: 60,
                  height: 55,
                  fontSize: 22,
                  formatter: `{b|${this._translocoService.translate(
                    'analysisResults.allocation-analytics.weight'
                  )}}\n{c}%`,
                  rich: {
                    b: { fontSize: 14, padding: [10, 0, 0, 0] },
                  },
                },
              },
            ],
          },
        },
        {
          query: {
            minWidth: 331,
            maxWidth: 443,
          },
          option: {
            legend: {
              right: '4%',
              textStyle: { fontSize: 12, padding: [0, 0, 0, 5] },
            },
            series: [
              {
                hoverOffset: 10,
                radius: ['32%', '65%'],
                center: ['32%', '50%'],
                label: {
                  padding: 18,
                  width: 45,
                  height: 45,
                  fontSize: 22,
                  formatter: `{b|${this._translocoService.translate(
                    'analysisResults.allocation-analytics.weight'
                  )}}\n{c}%`,
                  rich: {
                    b: { fontSize: 12, padding: [8, 0, 0, 0] },
                  },
                },
              },
            ],
          },
        },
        {
          query: {
            minWidth: 294,
            maxWidth: 330,
          },
          option: {
            legend: {
              right: 5,
              textStyle: { fontSize: 11, padding: [0, 0, 0, 5] },
            },
            series: [
              {
                hoverOffset: 7,
                radius: ['35%', '70%'],
                center: ['30%', '50%'],
                label: {
                  padding: 20,
                  width: 38,
                  height: 38,
                  fontSize: 18,
                  formatter: `{b|${this._translocoService.translate(
                    'analysisResults.allocation-analytics.weight'
                  )}}\n{c}%`,
                  rich: {
                    b: { fontSize: 11, padding: [6, 0, 0, 0] },
                  },
                },
              },
            ],
          },
        },
        {
          query: {
            maxWidth: 293,
          },
          option: {
            legend: {
              right: 0,
              textStyle: { fontSize: 11, padding: 0 },
            },
            series: [
              {
                hoverOffset: 4,
                radius: ['28%', '53%'],
                center: ['26%', '50%'],
                label: {
                  padding: [20, 16, 12, 16],
                  width: 25,
                  height: 25,
                  fontSize: 17,
                  formatter: '{c}%',
                  rich: {},
                },
              },
            ],
          },
        },
      ],
    };
  }

  getLiquiditiesOptions(
    liquidities: Liquidity[][],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const series: BarSeriesOption[] = [];
    const yAxisData: string[] = [];
    const getSeriesDataArray = (data: object): BarSeriesOption[] => data as BarSeriesOption[];
    const compare = (
      a: BarSeriesOption | OptionDataItemObject<BarSeriesOption>,
      b: BarSeriesOption | OptionDataItemObject<BarSeriesOption>
    ): number => {
      const nameA = a.name;
      const nameB = b.name;
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    };

    liquidities.forEach((liquidity, index) => {
      const allocationName = this._getAllocationName(index);
      yAxisData.push(allocationName);
      liquidity.forEach(liquidityItem => {
        const currentLiquidity = availableLiquidities.find(
          availableLiquidity => availableLiquidity.name.en === liquidityItem.label
        );
        if (liquidityItem.value !== 0) {
          const data = {
            name: allocationName,
            value: liquidityItem.value,
            itemStyle: {
              color: currentLiquidity.color,
            },
          };
          const existingSeriesItem = series.find(seriesItem => seriesItem.name === currentLiquidity.name[currentLang]);
          if (existingSeriesItem) {
            getSeriesDataArray(existingSeriesItem.data).push(data);
          } else {
            series.push({
              name: currentLiquidity.name[currentLang],
              type: 'bar',
              barMaxWidth: 30,
              barCategoryGap: '70%',
              stack: 'liquidities',
              emphasis: {
                itemStyle: {
                  color: currentLiquidity.color,
                  shadowColor: hexToRgba(currentLiquidity.color, 0.7),
                  shadowOffsetY: 3,
                  shadowBlur: 7,
                },
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c}%',
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 14,
                },
              },
              data: [data],
            });
          }
        }
      });
    });
    series.forEach(seriesItem => {
      liquidities.forEach((_liquidity, index) => {
        const allocationName = this._getAllocationName(index);
        if (!getSeriesDataArray(seriesItem.data).find(dataItem => dataItem.name === allocationName)) {
          getSeriesDataArray(seriesItem.data).push({
            name: allocationName,
            value: null,
            itemStyle: {
              color: availableLiquidities.find(
                availableLiquidity => availableLiquidity.name[currentLang] === seriesItem.name
              ).color,
            },
          } as OptionDataItemObject<BarSeriesOption>);
        }
      });
      getSeriesDataArray(seriesItem.data).sort(compare);
    });
    const sortedSeries: BarSeriesOption[] = this._sortLiquiditiesByNames(availableLiquidities, series, currentLang);

    return {
      baseOption: {
        textStyle: {
          fontFamily: 'BnpSans-Regular',
        },
        grid: {
          left: 10,
          top: 5,
          right: 10,
          height: '85%',
          containLabel: true,
        },
        color: sortedSeries.map(seriesItem => getSeriesDataArray(seriesItem.data)[0].itemStyle.color as ZRColor),
        legend: {
          top: 'bottom',
          itemWidth: 20,
          data: sortedSeries.map(seriesItem => String(seriesItem.name)),
        },
        tooltip: { show: false },
        yAxis: {
          type: 'category',
          show: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: true, margin: 20, padding: [3, 0, 0, 0], fontWeight: 'bold' },
          // @FIXME
          // boundaryGap: ['0%', '0%'],
        },
        xAxis: {
          type: 'value',
          position: 'top',
          show: false,
        },
        series: sortedSeries,
      },
      media: [
        {
          query: {
            minWidth: 326,
          },
          option: { legend: { textStyle: { fontSize: 12 } }, yAxis: { data: yAxisData.reverse() } },
        },
        {
          query: {
            maxWidth: 325,
          },
          option: {
            legend: { textStyle: { fontSize: 11 } },
            yAxis: { data: yAxisData.map((_dataItem, index) => `ALLOC. ${index + 1}`).reverse() },
          },
        },
      ],
    };
  }

  getExportLiquidityOptions(
    liquidity: Liquidity[],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const { baseOption, media } = this.getLiquidityOptions(liquidity, availableLiquidities, currentLang);
    baseOption.legend = { ...baseOption.legend, ...(media[2].option.legend as object) };
    return {
      ...baseOption,
      series: (baseOption.series as PieSeriesOption[]).map((pieSeries: PieSeriesOption) => ({
        ...pieSeries,
        radius: ['35%', '70%'],
        center: ['35%', '50%'],
        label: {
          show: true,
          position: 'outside',
          formatter: '{c}%',
          fontSize: 18,
        },
        labelLine: { show: false, length: 12, length2: 5 },
      })),
    };
  }

  getExportLiquiditiesOptions(
    liquidities: Liquidity[][],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): EChartsOption {
    const { baseOption, media } = this.getLiquiditiesOptions(liquidities, availableLiquidities, currentLang);
    baseOption.legend = { ...baseOption.legend, ...(media[0].option.legend as object) };
    baseOption.yAxis = { ...baseOption.yAxis, ...(media[0].option.yAxis as object) };
    return {
      ...baseOption,
      series: (baseOption.series as BarSeriesOption[]).map((seriesItem: BarSeriesOption) => ({
        ...seriesItem,
        label: {
          ...seriesItem.label,
          show: true,
          position: 'top',
          formatter: '{c}%',
        },
      })),
    };
  }

  private _createDiagonalPattern(color1: string, color2: string): ImagePatternObject {
    const canvasSide = 10;
    const lineWidth = 2;
    const factor = 0.66; // nasty Pythagoras approximation, enough for our purposes
    const canvas = document.createElement('canvas');
    canvas.width = canvasSide;
    canvas.height = canvasSide;
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = color1;
    ctx.fillRect(0, 0, canvasSide, canvasSide);

    ctx.strokeStyle = color2;
    ctx.lineWidth = lineWidth;
    ctx.moveTo(0, 0);
    ctx.lineTo(canvasSide, canvasSide);
    ctx.stroke();

    // add triangles to top right and bottom left to complete the repeated pattern
    ctx.beginPath();
    ctx.moveTo(0, canvasSide - lineWidth * factor);
    ctx.lineTo(0, canvasSide);
    ctx.lineTo(lineWidth * factor, canvasSide);
    ctx.closePath();
    ctx.fillStyle = color2;
    ctx.fill();
    ctx.beginPath();
    ctx.moveTo(canvasSide - lineWidth * factor, 0);
    ctx.lineTo(canvasSide, 0);
    ctx.lineTo(canvasSide, lineWidth * factor);
    ctx.closePath();
    ctx.fillStyle = color2;
    ctx.fill();

    return {
      type: 'pattern',
      image: canvas,
      repeat: 'repeat',
    };
  }

  private _buildContributionSeries(data: {
    weight: Array<{ id: number; name: string; color: string; value: number }>;
    return: Array<{ id: number; name: string; color: string; value: number }>;
    risk: Array<{ id: number; name: string; color: string; value: number }>;
  }): PieSeriesOption[] {
    const baseObject: Partial<PieSeriesOption> = {
      type: 'pie',
      label: {
        show: false,
      },
      labelLine: {
        lineStyle: {
          color: palette.grey[400],
        },
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 3,
      },
      stillShowZeroSum: false,
      avoidLabelOverlap: true,
    };
    return [
      {
        ...baseObject,
        name: this._translocoService.translate('analysisResults.contribution.analytics-labels')[0],
        data: data.weight,
      },
      {
        ...baseObject,
        name: this._translocoService.translate('analysisResults.contribution.analytics-labels')[1],
        data: data.return,
      },
      {
        ...baseObject,
        name: this._translocoService.translate('analysisResults.contribution.analytics-labels')[2],
        data: data.risk,
      },
    ];
  }

  private _getAllocationName(allocIndex: number): string {
    return `${this._translocoService.translate('global.allocation').toUpperCase()} ${allocIndex + 1}`;
  }

  private _sortLiquiditiesByNames(
    availableLiquidities: AvailableLiquidity[],
    liquiditiesData: Array<OptionDataItemObject<BarSeriesOption>> | BarSeriesOption[],
    currentLang: AvailableLang
  ): BarSeriesOption[] {
    const referenceLiquidityOrder = availableLiquidities.map(x => x.name[currentLang]);
    return liquiditiesData.sort(
      (a, b) => referenceLiquidityOrder.indexOf(a.name) - referenceLiquidityOrder.indexOf(b.name)
    );
  }
}
