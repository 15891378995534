import { CallbackDataParams } from 'echarts/types/dist/shared';

import { GeneratePieSeriesOptions } from './generate-pie-series-options';

export const DEFAULT_PIE_SERIES_OPTIONS: GeneratePieSeriesOptions = { overrides: {}, titleMargin: 0, chartMargin: 0 };

export const DEFAULT_PIE_SERIES_OPTIONS_PPTX: GeneratePieSeriesOptions = {
  titleMargin: 4,
  chartMargin: 0.7,
  overrides: {
    label: {
      show: true,
      formatter: (params: CallbackDataParams & { data: { isNegative: boolean; value: number } }): string => {
        const formatterData = params;
        return `${formatterData.data.isNegative ? '-' : ''}${formatterData.data.value}%`;
      },
    },
  },
};
